<template>
    <div>
        <!--form add & update adverts-->
        <ek-dialog
            ref="dialog"
            @ok="submitForm"
            @close="resetForm"
            :isEdit="!!advertDto.id"
            :title="advertDto.id ? 'تعديل اعلان' : 'اضافة اعلان'"
            @delete="deleteAd(advertDto.id)"
            @search="setSearch"
            btnText="اعلان جديد"
            placeholder="ابحث عن اعلان"
        >
            <template #body>
                <div>
                    <validationObserver ref="classForm">
                        <b-form>
                            <h4>ارسال إلى:</h4>
                            <b-form-checkbox
                                ref="select"
                                v-model="allSelected"
                                @change="studentAll"
                                >جميع الكليات
                            </b-form-checkbox>
                            <ek-input-select
                                v-if="advertDto.id == null"
                                name="sendTo"
                                multiple
                                placeholder="حدد  نوع الكلية"
                                label="الكلية  "
                                textLabel="followTo"
                                :options="facultyName"
                                v-model="advertDto.facultyIds"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: ' نوع الكلية مطلوب',
                                    },
                                ]"
                            ></ek-input-select>
                            <ek-input-select
                                v-if="advertDto.id"
                                name="sendTo"
                                multiple
                                placeholder="حدد  نوع الكلية"
                                label="الكلية  "
                                :options="facultyName"
                                v-model="advertDto.faculties"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: ' نوع الكلية مطلوب',
                                    },
                                ]"
                            ></ek-input-select>

                            <ek-input-text
                                v-model="advertDto.title"
                                name=" عنوان الإعلان"
                                label=" عنوان الإعلان"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: ' عنوان الإعلان مطلوب',
                                    },
                                ]"
                                placeholder="عنوان الإعلان مطلوب"
                            ></ek-input-text>

                            <ek-input-text
                                name="  ترتيب الأعلان"
                                label="  ترتيب الإعلان"
                                ref="order"
                                v-model="advertDto.priority"
                                placeholder=" ادخل ترتيب الإعلان "
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اضافة ترتيب الإعلان مطلوب',
                                    },
                                ]"
                            ></ek-input-text>

                            <ek-date-picker
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'يرجى ادخال بداية الإعلان ',
                                    },
                                ]"
                                name="date"
                                placeholder="ادخل بداية الاعلان"
                                label=" بداية الإعلان"
                                v-model="advertDto.startDate"
                            ></ek-date-picker>

                            <ek-date-picker
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'يرجى ادخال نهاية الإعلان ',
                                    },
                                ]"
                                name="dataa"
                                placeholder="ادخل نهاية الاعلان"
                                label=" نهاية الإعلان"
                                v-model="advertDto.endDate"
                            ></ek-date-picker>

                            <label>صورة الإعلان</label>
                            <ek-input-image
                                :image="advertDto.url == ''"
                                name="img"
                                ref="imgFile"
                                @input="uploadAdFile($event)"
                                accept="image/png, image/jpeg"
                            >
                                <h5>اسحب الملف او انقر للتحميل</h5>
                            </ek-input-image>
                            <div v-if="advertDto.id">
                                <label>الصورة الحالية</label>
                                <img
                                    class="w-100 h-100 mt-2"
                                    :src="
                                        $store.getters['app/domainHost'] +
                                        '/' +
                                        advertDto.url
                                    "
                                />
                            </div>
                        </b-form>
                    </validationObserver>
                </div>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
    computed: {
        ...mapState({
            studentName: (state) => state.students.studentName,
            facultyName: (state) => state.faculty.facultyName,
            advertDto: (state) => state.adverts.advertDto,
            isDialogOpen: (state) => state.adverts.isDialogOpen,
            url: (state) => state.subjects.url,
        }),
        ...mapGetters(["getUrl"]),
        // studentNames() {
        //     return this.advertDto.students.map(
        //         (student) => student.studentName
        //     );
        // },
        // studentIds() {
        //     return this.advertDto.students.map((student) => student.studentId);
        // },
    },
    data() {
        return {
            selecte: [],
            allSelected: false,
        };
    },
    methods: {
        //check add student
        studentAll(checked) {
            this.selecte = checked ? this.facultyName.slice() : [];
            this.advertDto.facultyIds = this.selecte.map((el) => el.id);
            this.advertDto.faculties = this.selecte.map((el) => el.id);
        },
        onChange(studentId) {
            this.getStudentsName(studentId);
        },
        submitForm() {
            this.$refs.classForm.validate().then((suc) => {
                if (suc) {
                    if (this.advertDto.id) {
                        this.updateAds({
                            ...this.advertDto,
                            facultyIds: this.advertDto.faculties,
                        });
                        this.$refs.dialog.close();
                    } else {
                        this.addAds({
                            ...this.advertDto,
                            url: this.url,
                        })
                            .then((data) => {
                                console.log(data);
                                this.$refs.dialog.close();
                            })
                            .catch(() => {});
                    }
                }
            });
        },

        updateAdsDate($event) {
            this.Update_Ads_Dto_Date($event);
        },
        uploadAdFile(event) {
            if (event && event.file) {
                this.addFile({ file: event.file, folderName: "Ad" }).then(
                    (res) => {
                        this.advertDto.url = res.data;
                    }
                );
            }
        },
        deleteAd(id) {
            this.deleteAd(id);
            this.$refs.dialog.close();
        },
        resetForm() {
            this.$refs.classForm.reset();
            this.$refs.imgFile.reset();
            this.$store.commit("SET_ADS_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["title", "startDate", "endDate"],
                query,
            });
        },
        ...mapActions([
            "addAds",
            "updateAds",
            "deleteAd",
            "addFile",
            "getStudentsName",
        ]),
        ...mapMutations(["Update_Ads_Dto_Date"]),
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>
